import { computed } from "@vue/composition-api";

export type FormFieldTypeRegular =
  | "name"
  | "city"
  | "street"
  | "postalCode"
  | "nip"
  | "phoneNumber"
  | "representativeFirstName"
  | "representativeLastName"
  | "representativePhoneNumber"
  | "representativeEmail"
  | "consent"
  | "general"
  | "additives"
  | "representatives"
  | "stand"
  | "standWidth"
  | "standDepth"
  | "numberOfTables"
  | "numberOfChairs"
  | "accessToElectricity"
  | "power"
  | "attentions"
  | "buildingIsRequired"
  | "buildingCompany"
  | "buildingPhoneNumber"
  | "buildingEmail";

export type FormFieldTypeAdditional =
  | "phone"
  | "number"
  | "text"
  | "textarea"
  | "radioGroup"
  | "checkboxGroup"
  | "select"
  | "informationField";

export type FormGroupType =
  | "fields"
  | "consents"
  | "general"
  | "additives"
  | "representatives"
  | "stand";

export type FormFieldType = FormFieldTypeRegular | FormFieldTypeAdditional;

export interface FormField {
  id: string;
  type: FormFieldType;
  name: string;
  label: string;
  options: {
    required: boolean;
    size: number;
    items?: Array<{
      id: string;
      text: string;
      value: string;
    }>;
  };
}

export interface FormGroup {
  id: string;
  name: string;
  type: FormGroupType;
  fields: FormField[];
}

export default function useCompanyRegistrationForm({ root }: any) {
  const availableFields: {
    [key in "regular" | "additional"]: FormFieldType[];
  } = {
    regular: [
      "standWidth",
      "standDepth",
      "numberOfTables",
      "numberOfChairs",
      "accessToElectricity",
      "power",
      "buildingIsRequired",
      "buildingCompany",
      "buildingPhoneNumber",
      "buildingEmail",
    ],
    additional: [
      "phone",
      "number",
      "text",
      "textarea",
      "radioGroup",
      "checkboxGroup",
      "select",
      "informationField",
    ],
  };

  const fieldTypes = computed<
    {
      [key in FormFieldType]: {
        name: string;
        icon: string;
        defaultLabel: string;
        protected?: boolean;
        required?: boolean;

        hasItems?: boolean;
        defaultItems?: any[];
        editableItems?: boolean;
      };
    }
  >(() => ({
    name: {
      name: root.$tc("layout.misc.name"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.name"),
      protected: true,
      required: true,
    },
    city: {
      name: root.$tc("layout.misc.city"),
      icon: "mdi-city-variant-outline",
      defaultLabel: root.$tc("layout.misc.city"),
      protected: true,
      required: true,
    },
    street: {
      name: root.$tc("layout.misc.streetAndNumber"),
      icon: "mdi-map-marker-outline",
      defaultLabel: root.$tc("layout.misc.streetAndNumber"),
      protected: true,
      required: true,
    },
    postalCode: {
      name: root.$tc("layout.misc.postalCode"),
      icon: "mdi-email-outline",
      defaultLabel: root.$tc("layout.misc.postalCode"),
      protected: true,
    },
    nip: {
      name: "NIP",
      icon: "mdi-rename-box",
      defaultLabel: "NIP",
      protected: true,
    },
    phoneNumber: {
      name: root.$tc("layout.misc.phone"),
      icon: "mdi-phone",
      defaultLabel: root.$tc("layout.misc.phone"),
      protected: true,
    },
    representativeFirstName: {
      name: root.$tc("layout.misc.representativeFirstName"),
      icon: "mdi-rename-box",
      defaultLabel: root.$tc("layout.misc.firstName"),
      protected: true,
    },
    representativeLastName: {
      name: root.$tc("layout.misc.representativeLastName"),
      icon: "mdi-rename-box",
      defaultLabel: root.$tc("layout.misc.lastName"),
      protected: true,
    },
    representativePhoneNumber: {
      name: root.$tc("layout.misc.representativePhoneNumber"),
      icon: "mdi-phone",
      defaultLabel: root.$tc("layout.misc.phone"),
      protected: true,
    },
    representativeEmail: {
      name: root.$tc("layout.misc.representativeEmail"),
      icon: "mdi-email-outline",
      defaultLabel: root.$tc("layout.misc.email"),
      protected: true,
    },

    phone: {
      name: root.$tc("layout.misc.phoneNumber"),
      icon: "mdi-phone",
      defaultLabel: root.$tc("layout.misc.phoneNumber"),
    },
    number: {
      name: root.$tc("layout.registrationFields.numberField"),
      icon: "mdi-numeric",
      defaultLabel: root.$tc("layout.registrationFields.numberField"),
    },

    text: {
      name: root.$tc("layout.registrationFields.textField"),
      icon: "mdi-form-textbox",
      defaultLabel: root.$tc("layout.registrationFields.textField"),
    },
    textarea: {
      name: root.$tc("layout.registrationFields.textarea"),
      icon: "mdi-form-textarea",
      defaultLabel: root.$tc("layout.registrationFields.textarea"),
    },
    radioGroup: {
      name: root.$tc("layout.registrationFields.singleChoiceField"),
      icon: "mdi-radiobox-marked",
      defaultLabel: root.$tc("layout.registrationFields.singleChoiceField"),
      hasItems: true,
    },
    checkboxGroup: {
      name: root.$tc("layout.registrationFields.mulitpleChoiceField"),
      icon: "mdi-checkbox-marked-outline",
      defaultLabel: root.$tc("layout.registrationFields.mulitpleChoiceField"),
      hasItems: true,
    },
    select: {
      name: root.$tc("layout.registrationFields.selectField"),
      icon: "mdi-form-select",
      defaultLabel: root.$tc("layout.registrationFields.selectField"),
      hasItems: true,
    },
    informationField: {
      name: root.$tc("layout.registrationFields.infoField"),
      icon: "mdi-information-outline",
      defaultLabel: root.$tc("layout.registrationFields.infoField"),
    },

    consent: {
      name: root.$tc("layout.misc.consent"),
      icon: "mdi-information-outline",
      defaultLabel: root.$tc("layout.misc.consent"),
    },

    general: {
      name: root.$tc("layout.misc.generalInfo"),
      icon: "mdi-information-outline",
      defaultLabel: root.$tc("layout.misc.generalInfo"),
    },

    additives: {
      name: root.$tc("layout.misc.services"),
      icon: "mdi-room-service-outline",
      defaultLabel: root.$tc("layout.misc.services"),
    },

    representatives: {
      name: root.$tc("layout.misc.additionalRepresentatives"),
      icon: "mdi-account-group-outline",
      defaultLabel: root.$tc("layout.misc.additionalRepresentatives"),
    },

    stand: {
      name: root.$tc("layout.misc.stand"),
      icon: "mdi-domiane",
      defaultLabel: root.$tc("layout.misc.stand"),
    },

    standWidth: {
      name: root.$tc("layout.misc.standWidth"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.standWidth"),
    },
    standDepth: {
      name: root.$tc("layout.misc.standDepth"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.standDepth"),
    },
    numberOfTables: {
      name: root.$tc("layout.misc.numberOfTables"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.numberOfTables"),
    },
    numberOfChairs: {
      name: root.$tc("layout.misc.numberOfChairs"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.numberOfChairs"),
    },
    accessToElectricity: {
      name: root.$tc("layout.misc.accessToElectricity"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.accessToElectricity"),
    },
    power: {
      name: root.$tc("layout.misc.power"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.power"),
    },
    attentions: {
      name: root.$tc("layout.misc.attentions"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.attentions"),
    },
    buildingIsRequired: {
      name: root.$tc("layout.misc.buildingIsRequired"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.buildingIsRequired"),
    },
    buildingCompany: {
      name: root.$tc("layout.misc.buildingCompany"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.buildingCompany"),
    },
    buildingPhoneNumber: {
      name: root.$tc("layout.misc.buildingPhoneNumber"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.phone"),
    },
    buildingEmail: {
      name: root.$tc("layout.misc.buildingEmail"),
      icon: "mdi-card-text-outline",
      defaultLabel: root.$tc("layout.misc.email"),
    },
  }));

  const isFieldValid = (
    field: FormField,
    fields: FormField[] = []
  ): boolean => {
    let valid = true;

    // jeżeli pole nie ma nazwy
    if (!field.name) valid = false;

    // jeżeli pole nie ma etykiety
    if (!field.label) valid = false;

    // jeżeli pole należy do pól dodatkowych
    if (availableFields.additional.includes(field.type)) {
      // jeżeli nazwa pola jest typem w grupie pól podstawowych
      if (availableFields.regular.includes(field.name as FormFieldType))
        valid = false;
    }

    // jeżeli pole powinno mieć opcje wyboru
    if (fieldTypes.value[field.type] && fieldTypes.value[field.type].hasItems) {
      // jeżeli pole nie posiada opcji wyboru
      if (!field.options.items || !field.options.items.length) {
        valid = false;
        // jeżeli pole posiada opcje wyboru
      } else {
        // sprawdzenie opcji
        for (const item of field.options.items) {
          // jeżeli opcja nie ma etykiety
          if (!item.text) valid = false;

          // jeżeli opcja nie ma wartości
          if (!item.value) valid = false;
        }
      }
    }

    // jeżeli podano listę wszystkich pól
    if (fields) {
      // porównywanie z innymi polami
      for (const f of fields) {
        // jeżeli nazwa pola się powtarza
        if (f.id !== field.id && f.name === field.name) valid = false;
      }
    }

    return valid;
  };

  return { availableFields, fieldTypes, isFieldValid };
}
