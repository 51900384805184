











































































































































































































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import useCompanyRegistrationForm, {
  FormGroup,
  FormFieldType,
  FormGroupType,
} from "@/use/companyRegistrationForm";

import useMisc from "@/use/misc";

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    draggable,
    OCompanyFieldEditor: () =>
      import(
        "@/components/organisms/globalTools/companyRegistrationForm/o-company-field-editor.vue"
      ),
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "add",
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props, { root }) {
    const {
      availableFields,
      fieldTypes,
      isFieldValid,
    } = useCompanyRegistrationForm({ root });
    const { toCamelCase } = useMisc({ root });

    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      valid: false,
      drag: false,
      validator: {
        enabled: false,
        progress: 0,
        message: "",
        error: "",
      },
    });

    const model = reactive<{
      name: string;
      groups: FormGroup[];
    }>({
      name: "",
      groups: [],
    });

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const dragOptions = {
      animation: 200,
      disabled: false,
      ghostClass: "ghost",
      scrollSensitivity: 200,
      forceFallback: true,
    };

    const checklist = computed((): {
      [key in FormFieldType]?: {
        isAdded: boolean;
        isAddedOnce: boolean;
      };
    } => {
      const checklistFields: FormFieldType[] = [
        "name",
        "city",
        "street",
        "postalCode",
        "nip",
        "phoneNumber",
        "representativeFirstName",
        "representativeLastName",
        "representativePhoneNumber",
        "representativeEmail",
      ];

      const result: {
        [key in FormFieldType]?: {
          isAdded: boolean;
          isAddedOnce: boolean;
        };
      } = {};

      for (const field of checklistFields) {
        result[field] = {
          isAdded: !!model.groups.find((g) =>
            g.fields.find((f) => f.type === field)
          ),
          isAddedOnce:
            model.groups
              .map((g) =>
                g.fields.filter((f) => f.type === field).map((f) => f.type)
              )
              .flat().length === 1,
        };
      }

      return result;
    });

    const createGroup = (type: FormGroupType = "fields") => {
      model.groups.push({
        id: uuid(),
        name: "",
        type,
        fields:
          type === "general"
            ? [
                {
                  id: uuid(),
                  type: "name",
                  name: "name",
                  label: "Nazwa",
                  options: { required: true, size: 12 },
                },
                {
                  id: uuid(),
                  type: "city",
                  name: "city",
                  label: "Miasto",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "street",
                  name: "street",
                  label: "Ulica",
                  options: { required: true, size: 4 },
                },
                {
                  id: uuid(),
                  type: "postalCode",
                  name: "postalCode",
                  label: "Kod pocztowy",
                  options: { required: true, size: 2 },
                },
                {
                  id: uuid(),
                  type: "nip",
                  name: "nip",
                  label: "NIP",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "phoneNumber",
                  name: "phoneNumber",
                  label: "Numer telefonu",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "representativeFirstName",
                  name: "representativeFirstName",
                  label: "Imię przedstawiciela",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "representativeLastName",
                  name: "representativeLastName",
                  label: "Nazwisko przedstawiciela",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "representativePhoneNumber",
                  name: "representativePhoneNumber",
                  label: "Telefon przedstawiciela",
                  options: { required: true, size: 6 },
                },
                {
                  id: uuid(),
                  type: "representativeEmail",
                  name: "representativeEmail",
                  label: "E-mail przedstawiciela",
                  options: { required: true, size: 6 },
                },
              ]
            : type === "consents"
            ? [
                {
                  id: uuid(),
                  type: "consent",
                  name: "marketingConsent",
                  label:
                    'Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych w celu marketingu produktów i usług Siemens Healthcare Sp z o.o. (administrator danych) i spółek z jej grupy kapitałowej, w tym również na kierowanie treści marketingowej w oparciu o moje preferencje (<a href="{website}/index.php/gdpr" target="_blank">szczegóły zgody</a>).',
                  options: { required: false, size: 12 },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "emailOffersConsent",
                  label:
                    "Wyrażam zgodę na otrzymywanie od Siemens Healthcare Sp. z o.o. drogą elektroniczną na wskazany przeze mnie w formularzu adres e-mail informacji handlowych, dotyczących produktów i usług oferowanych przez Siemens Healthcare Sp. o.o. i jej spółki powiązane, w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)",
                  options: { required: false, size: 12 },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "phoneOffersConsent",
                  label:
                    "Wyrażam zgodę na otrzymywanie telefonicznych połączeń przychodzących inicjowanych przez Siemens Healthcare Sp. z o.o. w celach handlowych i marketingowych produktów własnych i spółek powiązanych, zgodnie z art. 172 ustawy prawo telekomunikacyjne z dnia 16 lipca 2004 r. (Dz. U. z 2017 r. poz. 1907 z późń. zm.)",
                  options: { required: false, size: 12 },
                },
                {
                  id: uuid(),
                  type: "consent",
                  name: "termsConsent",
                  label:
                    'Akceptuję <a href="{website}/index.php/terms-of-service" target="_blank">regulamin</a> i <a href="{website}/index.php/privacy-policy" target="_blank">politykę prywatności</a> serwisu',
                  options: { required: true, size: 12 },
                },
              ]
            : type === "additives"
            ? []
            : type === "representatives"
            ? []
            : [],
      });
    };

    const createField = (type: FormFieldType) => ({
      id: uuid(),
      type: type,
      name: availableFields.regular.includes(type) ? type : "",
      label: fieldTypes.value[type].defaultLabel,
      options: {
        required: [
          "name",
          "city",
          "street",
          "postalCode",
          "nip",
          "phoneNumber",
          "representativeFirstName",
          "representativeLastName",
          "representativePhoneNumber",
          "representativeEmail",
        ].includes(type),
        items:
          fieldTypes.value[type] && fieldTypes.value[type].hasItems
            ? fieldTypes.value[type].defaultItems || []
            : undefined,
        size: 12,
      },
    });

    const getGroupTypeName = (type?: FormGroupType) => {
      switch (type) {
        case "general":
          return root.$tc("layout.misc.generalInfo");
        case "consents":
          return root.$tc("layout.sidebar.item.tools.terms.title");
        default:
        case "fields":
          return root.$tc(
            "panel.globalTools.registrationForm.form.groupOfFields"
          );
      }
    };

    const getGroupTypeIcon = (type?: FormGroupType) => {
      switch (type) {
        case "general":
          return "mdi-information-outline";
        case "consents":
          return "mdi-checkbox-multiple-marked-outline";
        default:
        case "fields":
          return "mdi-format-list-bulleted-square";
      }
    };

    const resetValidator = () => {
      state.validator.enabled = false;
      state.validator.progress = 0;
      state.validator.message = "";
    };

    const reset = () => {
      state.success = false;
      model.name = "";
      model.groups = [];

      resetValidator();
      state.validator.error = "";
    };

    /**
     * Form validation
     * @returns {Promise<void>} Validation status
     */
    const validateForm = () =>
      new Promise<void>((resolve, reject) => {
        let valid = true;

        const fields = model.groups.map((g) => g.fields).flat();

        state.validator.enabled = true;

        state.validator.progress = 25;
        state.validator.message = root.$tc("alerts.checkChecklist");

        if (
          !(
            checklist.value.name?.isAdded && checklist.value.name.isAddedOnce
          ) ||
          !(
            checklist.value.city?.isAdded && checklist.value.city.isAddedOnce
          ) ||
          !(
            checklist.value.street?.isAdded &&
            checklist.value.street.isAddedOnce
          ) ||
          !(
            checklist.value.postalCode?.isAdded &&
            checklist.value.postalCode.isAddedOnce
          ) ||
          !(checklist.value.nip?.isAdded && checklist.value.nip.isAddedOnce) ||
          !(
            checklist.value.phoneNumber?.isAdded &&
            checklist.value.phoneNumber.isAddedOnce
          ) ||
          !(
            checklist.value.representativeFirstName?.isAdded &&
            checklist.value.representativeFirstName.isAddedOnce
          ) ||
          !(
            checklist.value.representativeLastName?.isAdded &&
            checklist.value.representativeLastName.isAddedOnce
          ) ||
          !(
            checklist.value.representativePhoneNumber?.isAdded &&
            checklist.value.representativePhoneNumber.isAddedOnce
          ) ||
          !(
            checklist.value.representativeEmail?.isAdded &&
            checklist.value.representativeEmail.isAddedOnce
          )
        )
          valid = false;

        setTimeout(() => {
          if (!valid)
            return reject(root.$tc("alert.checklistConditionsNotMet.title"));

          state.validator.progress = 50;
          state.validator.message = root.$tc(
            "alert.checkingUniquenessOfFields.title"
          );

          const usedNames: string[] = [];

          for (const field of fields) {
            usedNames.push(field.name);
          }

          if (new Set(usedNames).size !== fields.length) valid = false;

          setTimeout(() => {
            if (!valid)
              return reject(
                root.$tc("alert.makeSureFieldNamesAreUnique.title")
              );

            state.validator.progress = 75;
            state.validator.message = root.$tc("alert.validatingFields.title");

            for (const field of fields) {
              if (!isFieldValid(field, fields)) valid = false;
            }

            setTimeout(() => {
              if (!valid)
                return reject(root.$tc("alert.checkTheCorectnessOfFields"));

              setTimeout(() => {
                state.validator.progress = 100;
                state.validator.message = root.$tc("alert.formIsCorrect.title");

                setTimeout(resolve, 500);
              }, 500);
            }, 500);
          }, 500);
        }, 500);
      });

    const fetch = () => {
      if (props.type === "edit" && props.id) {
        state.loading = true;

        axiosInstance.value
          .get(`company-registration-form-template/${props.id}`)
          .then(({ data }) => {
            model.name = data.companyRegistrationFormTemplate?.name || "";
            model.groups = data.companyRegistrationFormTemplate?.groups || [];

            validateForm()
              .catch((error) => {
                state.loading = false;
                state.validator.error = error;
              })
              .finally(resetValidator);
          })
          .catch((error) => (state.error = error.response.status))
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetch);
    onMounted(() => nextTick(fetch));

    /**
     * Form submit
     * @async
     */
    const submit = async () => {
      await form.value.validate();

      if (state.valid) {
        state.loading = true;

        validateForm()
          .then(() => {
            const data = {
              name: model.name,
              groups: model.groups || undefined,
            };

            if (props.type === "edit" && props.id) {
              axiosInstance.value
                .put(`company-registration-form-template/${props.id}`, data)
                .then(() => (state.success = true))
                .catch((error) => (state.error = error.response.status))
                .finally(() => (state.loading = false));
            } else {
              axiosInstance.value
                .post("company-registration-form-template", data)
                .then(() => (state.success = true))
                .catch((error) => (state.error = error.response.status))
                .finally(() => (state.loading = false));
            }
          })
          .catch((error) => {
            state.loading = false;
            state.validator.error = error;
          })
          .finally(resetValidator);
      } else {
        state.validator.error = root.$tc("alert.correctFormErrors");
      }
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.formAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      uuid,
      form,
      state,
      model,
      rules,
      availableFields,
      dragOptions,
      fieldTypes,
      createGroup,
      createField,
      getGroupTypeName,
      getGroupTypeIcon,
      reset,
      submit,
      getErrorMessage,
      checklist,
      isFieldValid,
      toCamelCase,
    };
  },
});
